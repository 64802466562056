// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");
import "../sweetalert";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//Sweetealert
import Swal from "sweetalert2/dist/sweetalert2.js";
window.Swal = Swal;

// chartkick
import "chartkick";
import "chart.js";
// import "chartkick/chart.js";
import "chartkick/highcharts";
// index.js
import "../src";
import "../src/chart_gr4";
import "../src/chart_gr4/index";
import "../src/main";

let jsVersion = "2024-04-22 09:20";
